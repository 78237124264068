@import "../../assets/scss/ColorVariable.scss";

.sub-heading {
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	text-align: center;
	color: rgba($black, 0.68);
	padding-bottom: 10px;
}
