@import "../../../assets/scss/mixin.scss";

.popup-class {
  .MuiPaper-root {
    box-sizing: initial;
    max-width: 400px;
    padding: 24px;
    background: #ffffff;
    box-shadow: 0px 48px 100px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    margin: 0;
    .notification-Icon {
      display: flex;
      justify-content: space-between;
      .closePopIcon {
        cursor: pointer;
      }
    }
    .popup-title {
      font-weight: 700;
      font-size: 28px;
      line-height: 30px;
      letter-spacing: 0.03em;
      padding: 0;
      margin-top: 24px;
    }
    .MuiDialogContent-root {
      padding: 0;
      .subtext-cards {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.03em;
        margin-top: 24px;
      }
    }
    .MuiDialogActions-root {
      padding: 0;
      margin-top: 24px;
      justify-content: center;
      .MuiButtonBase-root {
        @include buttonSize(196px);
        @include forPhoneOnly {
          min-width: 159.5px;
        }
        min-height: 48px;
        text-transform: capitalize;
        font-weight: 700;
        top: 0;
      }
      .MuiButton-outlined {
        border: 1px solid #d3d3d3;
      }
    }
  }
}
