// media

@mixin forPhoneOnly {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin notForMobile {
  @media (min-width: 800px) {
    @content;
  }
}

@mixin fordesktopOnly {
  @media (min-width: 1000px) {
    @content;
  }
}

@mixin heading-style($margin, $marginInPhone) {
  margin-top: $margin;
  padding: 0;
  @media (max-width: 600px) {
    margin-top: $marginInPhone;
  }
}

@mixin buttonSize($button-width) {
  min-width: $button-width;
  min-height: 46px;
  top: 20px;
}

@mixin imgSize {
  width: 77px;
  height: 77px;
  //margin-top: 24px;
  @media (max-width: 600px) {
    width: 56px;
    height: 56px;
  }
}

@mixin commonNoticeStyle {
  margin-top: 24px;
  .MuiTypography-root {
    font-size: 14px;
    color: rgba($black, 0.68);
  }
}

@mixin marginHeader {
  margin-top: 20px;
  @media (max-width: 600px) {
    margin-top: 76px;
  }
}

@mixin bodyWrapperPadding {
  padding-top: 59px;
  @include forPhoneOnly {
    padding-top: 46px;
  }
}

@mixin responsiveWidth {
  width: 460px;
  @media (max-width: 600px) {
    width: 343px;
  }
}

@mixin respondMax($max-width) {
  @media (max-width: $max-width) {
    @content;
  }
}

@mixin respondMin($min-width) {
  @media (min-width: $min-width) {
    @content;
  }
}