@import "../../assets/scss/mixin.scss";

.password-Box {
  .password-box {
    img {
      @include imgSize;
    }
    .MuiButton-root {
      &.MuiButton-contained {
        min-width: 188px;
        @include forPhoneOnly{
          min-width: 327px;
        }
      }
    }
  }
}
