@import '../../../assets/scss/ColorVariable.scss';
@import '../../../assets/scss/mixin.scss';
.top-bar-container{
margin-bottom: 59px;
}

.top-bar-Notification {
    background: $topNotipficationBar;
    // padding: 1rem 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;
    .MuiContainer-root {
      text-align: center;
      align-items: flex-start;
      color: $white;
      font-size: 12px;
      display: -webkit-box;
      line-height: 14.8px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      padding: 0 0 8px 0;
      display: flex;
      img {
        margin-right: 10px;
      }
    }
  }
  .top-bar-Notification-greek{
    @extend .top-bar-Notification;
   .MuiContainer-root {
     p{
      margin: 4px 0;
      font-size: 11.7px;
    }
   }
  }
  .top-bar-Notification-mobile {
    background: $topNotipficationBar;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
    .MuiContainer-root {
      width: 100%;
      font-size: 12px;
      display: block;
      padding-left: 0px;
      padding-right: 0px;
  }
  .MuiAccordion-root {
    background-color: $topNotipficationBar;
    color: $white;
    text-align: center;
  }
  .MuiAccordionSummary-content {
    align-items: flex-start;
    order: 1;
    margin: 0;
    margin-left: 7px;
    text-align: left;
    @include forPhoneOnly{
      margin-left: 3px;
    }
    img {
      margin-right: 10px;
      margin-left: 5px;
      @include forPhoneOnly{
        margin-right: 7px;
        margin-left: 5px;
        width: 15px;
      }
    }
  }
  .MuiAccordionSummary-root {
    // min-height: auto;
    align-items: flex-start;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
  .MuiAccordionSummary-expandIconWrapper {
    margin: 10px 0px;
  }
  .MuiSvgIcon-root {
    color: $white;
    @include forPhoneOnly{
      width: .6em;
      height: .6em;
    }
  }
}