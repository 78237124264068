@import "../../assets/scss/ColorVariable.scss";
@import "../../assets/scss/mixin.scss";

@mixin mlContactNumber {
  @media (max-width: 600px) {
    margin-left: 0px;
    width: inherit;
  }
}

@mixin mtPhoneIcon {
  @media (max-width: 600px) {
    height: 520px;
    padding: 24px 0px;

    .MuiTypography-body1 {
      width: 312px;
    }

    .MuiGrid-root.MuiGrid-item {
      width: 201px !important;
    }

    .checkbox .MuiCheckbox-root {
      margin-top: -7px !important;
    }
  }
}

@mixin lineWidth {
  @media (max-width: 600px) {
    width: 334px;
  }
}

@mixin mlcheckbox {
  // margin-left: 30px;

  @media (max-width: 600px) {
    margin-left: 0px;
  }
}

@mixin abouty-card {
  @media (max-width: 600px) {
    .subtext-cards {
      width: 327px !important;
      height: 40px !important;
    }

    .MuiBox-root {
      padding: 0 !important;
    }
  }
}

.abouty-card.MuiContainer-root {
  @include abouty-card();
  padding: 0;

  .subtext-cards {
    width: 489px;
    height: auto;
  }

  .MuiBox-root {
    padding: 0 24px;
  }

  .MuiButton-contained {
    height: 46px;
    width: 140px;
  }
}

.MuiGrid-root {
  .radio-group {
    text-align: initial;
  }

  .form-radio-control {
    &.MuiFormControl-root {
      text-align: initial;
      width: 100%;

      .MuiFormGroup-root {
        flex-direction: row;

        .MuiFormControlLabel-root {
          border: 1px solid #e6e6e6;
          border-radius: 4px;
          padding-right: 16px;
          margin-left: 0;
          display: flex;
          flex-direction: row-reverse;
          width: 100%;
          margin: 0;
          padding: 0;
          justify-content: space-between;

          .MuiFormControlLabel-label {
            font-size: 14px;
            color: rgba($black, 0.44);
            padding: 0px 15px;
          }
        }
      }

      .MuiRadio-root {
        span {
          color: $checkboxColor;
        }

        &.Mui-checked {
          span {
            color: $primary;
          }
        }
      }

      .MuiSvgIcon-root {
        width: 20px;
        height: 20px;
      }

      .helperText {
        background: #fdf0f2;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #F44336;
        width: 218px;
        height: 34px;
        padding-top: 7px;
        padding-left: 5px;
        margin-top: 3px;
      }
    }
  }
}

.cutome-date-picker {
  .MuiFormHelperText-root {
    margin: 0;
    background: #fdf0f2;
    border-radius: 4px;
    color: $red;
    font-weight: 500;
    padding: 10px;
    margin-top: 10px;
  }
}

.accented-text {
  label.Mui-focused {
    color: #dda034;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #dda034;
  }

  .MuiFormHelperText-root {
    color: rgba(0, 0, 0, 0.68) !important;
    background-color: #fff8ea !important;
  }
}

//citizenship card
.form-class {
  .MuiGrid-root {
    margin-top: 46px;

    @include forPhoneOnly {
      margin-top: 24px;
    }
  }
  .MuiAutocomplete-root .MuiFormControl-root .MuiFormLabel-root {
      line-height: 1;
  }
}


.custom-autoComplete {
  .MuiFormControl-root {
    min-width: 447px;
    height: 40px;

    @include forPhoneOnly {
      min-width: 343px;
    }

    .MuiFormLabel-root {
      left: 30px;
      line-height: 1.5;
      font-size: 14px;
      font-weight: 500;
      // color: $formLabelColor;
     
      &.MuiInputLabel-shrink {
        left: 0;
      }
    }

    .MuiInputBase-root {
      padding-left: 40px;

      &::before {
        content: "";
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
      }
    }

    .MuiButtonBase-root {
      background: none !important;
    }

    .MuiInputBase-input {
      height: auto;
      font-size: 14px;
      color: rgba($black, 0.66);
    }

    .MuiAutocomplete-popupIndicator {
      .MuiSvgIcon-root {
        fill: none;
      }
    }
  }
  &.custom_autoComplete_arabic{
    .MuiFormControl-root{
      .MuiFormLabel-root{
        left: inherit;
        right: 30px;
        line-height: 1.5;
        &.MuiInputLabel-shrink{
          right: 0;
        }
      }
      .MuiInputBase-root{
        &::before{
          left: inherit;
          right: 10px;
        }
      }
    }
    &.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.muirtl-l3ln04-MuiAutocomplete-root .MuiOutlinedInput-root{
      padding-left: 25px;
      padding-right: 40px;
    }
    &.MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-input{
      padding-right: 45px !important;
    }
  }
}

//contact number
.conatct-class-wrapper {
  @include mtPhoneIcon;

  .MuiBox-root {
    height: auto;
  }

  .MuiContainer-root {
    padding: 0;
    @include forPhoneOnly{
      padding: 0 10px;
    }
    .contact-header {
      height: 31px;
      width: 920px;
    }

    hr {
      width: 448px;
      margin-top: 22px;
      border: 1px solid #e5e5e5;
      @include lineWidth;
    }

    .MuiBox-root {
      @include mlContactNumber;

      .MuiGrid-root {
        display: flex;
        flex-direction: row;
        gap: 3px; //new
        @include forPhoneOnly{
          flex-wrap: nowrap;
        }
        .MuiAutocomplete-root {
          margin-top: 8px;
          width: 138px; //new

          fieldset {
            height: 43px;
          }
        }

        .MuiInputBase-formControl {
          padding-right: 0;

          .MuiAutocomplete-endAdornment {
            right: 4px;
          }
        }

        .MuiGrid-root {
          padding: 0;
          margin-top: 8px;

          .custom-form-controler1 {
            width: 305px;

            &.control-phone {
              .MuiFormControl-root {
                @media (max-width: 600px) {
                  width: 201px;
                }
              }

              .MuiFormLabel-root {
                line-height: 1.5;
                font-size: 14px;

                &.MuiInputLabel-shrink {
                  line-height: 1;
                }
              }
            }
          }
        }
      }
      .Mui-disabled{
        background-color: #f0f0f0 !important;
      }
    }

    .checkbox {
      .MuiFormControlLabel-root{
        align-items: flex-start;
      }
      .MuiCheckbox-root {
        margin-top: -7px;
      }

      @include mlcheckbox;

      .MuiFormControlLabel-root {
        .MuiTypography-root {
          font-weight: 400;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.68);
          line-height: 19px;
          text-align: initial;
        }
      }
    }

    .next-button-class {
      margin-top: 20px;
    }

    .consent-text {
      font-size: 14px;
      font-weight: 400;
      margin-top: 30px;
      margin-bottom: 10px;
      line-height: 20px;
      text-align: left;
      letter-spacing: 0.03em;
      font-weight: 400;
      font-size: 14px;
      color: rgba($black, 0.68);
      padding-left: 28px;
      @include forPhoneOnly{
        padding-left: 0;
      }
    }
  }
}

.custom-form-controler {
  &.MuiFormControl-root {
    .input-text-normal {
      .MuiFormHelperText-root {
        background: none;
        color: rgba($black, 0.6);
        margin: 0;
        padding: 5px 14px 0;
      }
    }
    .input-text-error{
      .MuiFormHelperText-root{
        background-color: $lightRed !important;
        color: $red !important;
      }
    }
    .MuiFormLabel-root {
      line-height: 1.25rem;
    }
    .MuiInputBase-root {
      input {
        height: 1.5rem;
      }
    }
  }
}
.mobile-fullWidth {
  @include forPhoneOnly {
    width: 100%;
  }
}

@mixin mlIDCard {
  @media (max-width: 600px) {
    height: 429px;
    width: auto; // new

    .custom-form-controler {
      width: 327px !important; // new
      height: auto !important;

      .MuiFormControl-fullWidth {
        width: inherit !important;
      }
    }

    .radio-button {
      width: 327px !important;

      p {
        width: 245px !important;
        height: 20px !important;
      }
    }

    .selected-radio-button {
      width: auto !important;

      .id-card-input {
        width: 295px !important;
      }
    }

    .au-select-box{
      background-color: white;
      width: 295px !important;
    }

    .MuiButton-contained {
      width: 300px !important;
      margin: auto;
      margin-top: 20px !important;
      margin-bottom: 10px;
    }
    .MuiButton-outline{
      width: 300px !important;
      margin: auto;
      margin-top: 20px !important;
    }
  }
}


//id number card
.identification-wrapper {
  @include mlIDCard;
  padding: 24px 0px;
  width: 920px;
  height: 570px;
  margin: auto;
  .subtext-cards.MuiTypography-root {
    @include forPhoneOnly {
      font-size: 14px;
    }
  }
  .id-card-number {
    padding: 0; // new

    .MuiFormControl-fullWidth {
      width: 448px;
      @include forPhoneOnly {
        width: 100% !important;
      }
      height: auto;
    }

    .password-validation-wrapper {
      background: #fafafa;
    }

    .MuiFormGroup-root {
      gap: 8px;

      .radio-button {
        display: flex;
        padding: 16px;
        width: 448px;
        height: 56px;
        border-radius: 90px;
        box-sizing: border-box;
        background: #f3f3f3;

        label {
          gap: 8px;
          margin: 0;
          width: 100%;
          justify-content: space-between;

          span {
            height: 20px;
            width: 20px;

            span {
              color: #d3d3d3;
            }
          }
        }

        p {
          width: 356px;
          white-space: nowrap;
          font-size: 14px;
          letter-spacing: 0.03em;
          color: rgba(0, 0, 0, 0.6);
          display: flex;
          gap: 5px;

          &.MuiTypography-root {
            .material-icons-outlined {
              margin-right: 5px;
            }
          }
        }
      }
    }
    .text-block{
      padding: 0;
      margin: 0;
      display: flex;
    }

    .selected-radio-button {
      width: 448px;
      box-sizing: border-box;
      border: 1px solid #0071eb;
      border-radius: 16px;
      padding: 16px;
      background: #0071eb;

      .single-id-box{
        display: flex;
        gap: 8px;
        font-weight: 700;
      }
      .id-helptext{
        text-align: start;
        font-size: 12px;
      }
      label {
        margin: 0;
        align-items: flex-start;
        width: 100%;
        justify-content: space-between;

          span.Mui-checked {
            background: #ffffff;
            border: 1px solid #d3d3d3;
            height: 20px;
            width: 20px;

            +.MuiTypography-root {
              .material-icons-outlined {
                color: $white;
              }
            }
          }

          p {
            display: flex;
            color: #ffffff;
            font-size: 14px;
            letter-spacing: 0px;

            img {
              height: 24px;
            }

            div {
              display: block;
              font-weight: 400;
              text-align: left;
              margin-left: 10px;
              padding: 0 9px;
            }
          }
        }

        .id-card-input {
          width: 416px;
          height: 44px;
          background: #ffffff;
          border: 1px solid #d3d3d3;
          border-radius: 4px;
          box-sizing: border-box;

          input {
            padding: 10px 5px;
          }

          p {
            top: 20px;
            position: relative;
          }
       }
       &.selected-radio-button-one{
        background: #0071eb;
        color: $white;
        border: none;
        .MuiTypography-root{
          img{
            filter: brightness(0) invert(1);
            
          }
         }
       }
       .MuiOutlinedInput-notchedOutline{
        border: none;
       }
       .MuiAutocomplete-root .MuiFormControl-root .MuiFormLabel-root{
          line-height: 1.6;
          font-size: 14px;
       }
       .MuiSvgIcon-root{
        color: $primary;
       }
       .MuiTypography-root{
        img{
          filter: brightness(0) invert(1);
        }
       }
       .text-block{
        padding: 0;
        margin: 0;
        display: flex;
        .MuiSvgIcon-root{
          color: $white;
        }
      }
       
     }

    .MuiFormHelperText-root {
      margin-top: 0;
      padding: 0;
    }

    .MuiButton-contained {
      height: 46px;
      width: 450px;
    }
    .MuiButton-outline{
      height: 46px;
      width: 450px;
    }

    .MuiButton-contained.Mui-disabled {
      background-color: #f0f0f0;
    }
  }
}

//id number card
.identification-wrapper2 {
  @include mlIDCard;
  padding: 24px 0px;
  width: 920px;
  height: 570px;
  margin: auto;
  .subtext-cards.MuiTypography-root {
    @include forPhoneOnly {
      font-size: 14px;
    }
  }
  .id-card-number {
    padding: 0; // new

    .MuiFormControl-fullWidth {
      width: 448px;
      @include forPhoneOnly {
        width: 295px !important;
      }
      height: auto;
    }

    .validation-wrapper {
      background-color: #FDF0F2;
      margin: 5px 0;
      padding: 7px;
      border-radius: 4px;
      color: #B61E3A;
      font-weight: 500;
    }
     .error-box-width{
      width: 440px;
      @include forPhoneOnly{
        width: 295px !important;
      }
     }
     .error-box-width-medi{
      width: 440px;
      @include forPhoneOnly{
        position: relative;
        left: 12px;
        width: 286px !important;
      }
     }
    .MuiFormGroup-root {
      gap: 8px;

      .radio-button {
        display: flex;
        padding: 16px;
        width: 448px;
        height: 56px;
        border-radius: 90px;
        box-sizing: border-box;
        background: #f3f3f3;

        label {
          gap: 8px;
          margin: 0;
          width: 100%;
          justify-content: space-between;

          span {
            height: 20px;
            width: 20px;

            span {
              color: #d3d3d3;
            }
          }
        }

        p {
          width: 356px;
          font-size: 14px;
          letter-spacing: 0.03em;
          color: rgba(0, 0, 0, 0.6);
          display: flex;
          gap: 5px;

          &.MuiTypography-root {
            .material-icons-outlined {
              margin-right: 5px;
            }
          }
        }
      }
    }
    .text-block{
      padding: 0;
      margin: 0;
      display: flex;
    }
    .selected-radio-button {
      width: 448px;
      box-sizing: border-box;
      // border: 1px solid #0071eb;
      border-radius: 16px;
      // padding: 16px;
      margin-top: 16px;
      // background: #0071eb;

      .single-id-box{
        display: flex;
        gap: 8px;
        font-weight: 700;
      }
      .id-helptext{
        text-align: start;
        font-size: 12px;
      }
      label {
        align-items: flex-start;
        // width: 100%;
        justify-content: space-between;
        margin: 10px 0px;
        background: white;

          span.Mui-checked {
            background: #ffffff;
            border: 1px solid #d3d3d3;
            height: 20px;
            width: 20px;

            +.MuiTypography-root {
              .material-icons-outlined {
                color: $white;
              }
            }
          }

          p {
            display: flex;
            color: #ffffff;
            font-size: 14px;
            letter-spacing: 0px;

            img {
              height: 24px;
            }

            div {
              display: block;
              font-weight: 400;
              text-align: left;
              margin-left: 10px;
              padding: 0 9px;
            }
          }
        }

        .id-card-input{
          width: -webkit-fill-available;
          height: 44px;
          background: #ffffff;
          border: 1px solid #d3d3d3;
          border-radius: 4px;
          box-sizing: border-box;

          input {
            // padding: 10px 5px;
            padding: 10px;
          }

          p {
            top: 20px;
            position: relative;
          }
       }
        .id-card-input-error{
        border: 1px solid #F44336;
       }
      .ref-id-input{
        margin-top: 10px;
       }
       .medi-number-input{
        width: 300px !important;
       }
       &.selected-radio-button-one{
        background: #0071eb;
        color: $white;
        border: none;
        .MuiTypography-root{
          img{
            filter: brightness(0) invert(1);
            
          }
         }
       }
       .MuiOutlinedInput-notchedOutline{
        border: none;
       }
       .MuiAutocomplete-root .MuiFormControl-root .MuiFormLabel-root{
          line-height: 1.6;
          // font-size: 14px;
          font-size: 1rem;
          margin: 0px !important;
       }
       .MuiSvgIcon-root{
        color: $primary;
       }
       .MuiTypography-root{
        img{
          filter: brightness(0) invert(1);
        }
       }
       .text-block{
        padding: 0;
        margin: 0;
        display: flex;
        .MuiSvgIcon-root{
          color: $white;
        }
      }
       
     }
    .selected-radio-button2 {
      // box-sizing: border-box;
      border: 1px solid #0071eb;
      border-radius: 16px;
      padding: 16px;
      // background: #0071eb;

      .single-id-box{
        display: flex;
        gap: 8px;
        font-weight: 700;
      }
      .id-helptext{
        text-align: start;
        font-size: 12px;
      }
      label {
        margin: 0;
        align-items: flex-start;
        width: 100%;
        justify-content: space-between;

          span.Mui-checked {
            background: #ffffff;
            border: 1px solid #d3d3d3;
            height: 20px;
            width: 20px;

            +.MuiTypography-root {
              .material-icons-outlined {
                color: $white;
              }
            }
          }

          p {
            display: flex;
            color: #ffffff;
            font-size: 14px;
            letter-spacing: 0px;

            img {
              height: 24px;
            }

            div {
              display: block;
              font-weight: 400;
              text-align: left;
              margin-left: 10px;
              padding: 0 9px;
            }
          }
        }

        .id-card-input {
          width: 416px;
          height: 44px;
          background: #ffffff;
          border: 1px solid #d3d3d3;
          border-radius: 4px;
          box-sizing: border-box;

          input {
            padding: 10px 5px;
          }

          p {
            top: 20px;
            position: relative;
          }
       }
       &.selected-radio-button-one{
        background: #0071eb;
        color: $white;
        border: none;
        .MuiTypography-root{
          img{
            filter: brightness(0) invert(1);
            
          }
         }
       }
       .MuiOutlinedInput-notchedOutline{
        border: none;
       }
       .MuiAutocomplete-root .MuiFormControl-root .MuiFormLabel-root{
          line-height: 1.6;
          font-size: 14px;
       }
       .MuiSvgIcon-root{
        color: $success !important;
        font-size: small !important;
       }
       .MuiTypography-root{
        img{
          filter: brightness(0) invert(1);
        }
       }
       .text-block{
        padding: 0;
        margin: 0;
        display: flex;
        .MuiSvgIcon-root{
          color: $white;
        }
      }
       
     }

    .MuiFormHelperText-root {
      margin-top: 0;
      padding: 0;
    }

    .MuiButton-contained {
      height: 46px;
      width: 450px;
    }
    .MuiButton-outline{
      height: 46px;
      width: 450px;
    }

    .MuiButton-contained.Mui-disabled {
      background-color: #f0f0f0;
    }
  }
  .medicare-id-number{
    &.MuiAutocomplete-root .MuiFormControl-root .MuiFormLabel-root{
      margin: 4.8px !important;
    }
  }
  .need-help-wrapper{
    text-align: right;
    position:relative;
    max-width: 450px;
    a.need-help{
      text-decoration: underline !important;
      position: absolute;
      right: 0px;
    }
    @include forPhoneOnly{
      max-width: 318px;
    }
  }
}
.no-asterisk {
  .MuiFormLabel-asterisk {
    display: none;
  }
}

.contact-auto-complete {
  &.MuiAutocomplete-root {
    .MuiFormControl-root {
      .MuiFormLabel-root {
        line-height: 1.4;
        font-size: 14px;
        margin-top: 2px;
        @include forPhoneOnly {
          font-size: 11px;   
          margin-top: 3px;
        }
      }
    }
  }
  &.MuiAutocomplete-root{
    .MuiOutlinedInput-root{
     .MuiAutocomplete-endAdornment{
      @include forPhoneOnly{
        right: 1px;
      }
     } 
    }
  }

  &.contact-auto-complete_arabic{
    position: relative;
    &.MuiAutocomplete-root{
      .MuiFormControl-root{
        .MuiAutocomplete-endAdornment{
          right: initial !important;
          width: 28px;
          height: 28px;
          left: 2px;
          .MuiAutocomplete-clearIndicator{
            display: none;
          }
        }
        
        .MuiInputBase-root {
          padding-left: 24px;
          padding-right: 32px;
          .MuiInputBase-input{
            margin-left: 0;
            padding: 0 !important;
          }
        }
      }
    }
    > span{
      transform: translateY(-50%) !important;
      top: 50% !important;
      margin-left: 0px !important;
      margin-right: 10px !important;
      height: 20px;
    }
    
  }
}
// for cxp only working
.custom-form-label {
  .MuiFormLabel-root {
    line-height: 1.4;
    font-size: 14px;
    margin-top: 2px;
    @include forPhoneOnly {
      font-size: 11px !important;   
      margin-top: 3px !important;
    }
  }
  .MuiAutocomplete-popupIndicator {
  padding: 1px !important;
  }
}

.custom-autocomplete-endAdornment {
  .MuiAutocomplete-endAdornment {
    @include forPhoneOnly {
      right: 1px !important;
    }
  }
}

/* Welcome Screen Changes */
.welcome {
  & h1 {
    margin: 0px 0px 22px 0px;
    font-size: 36px;
    font-weight: 500;
  }

  & button {
    font-weight: 700;
  }
}

.welcomeback-screen {
  display: flex;
  justify-content: center;

  svg {
    fill: #fcaf1f;
  }

  div p {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.68);
    margin-top: 0px;
    margin-left: 7px;
  }
}

//AU - ID Card
.au-auto-select-box{
  background-color: white;
  margin-bottom: 10px;
  max-width: 416px;

  @media (max-width: 375px) {
    background-color: white;
    margin-bottom: 10px;
    width: 175px;
  }
}

.radio-btn-label{
  display: flex;
  flex-direction: row;
}

.au-card-input{
  margin: 2px 2px;
}

.au_country_dropdown {
  & .MuiTextField-root{
    // border:none !important;
    max-width: 450px !important;
    margin-bottom: 10px;
    background: #ffffff;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    box-sizing: border-box;
    .MuiInputLabel-root{
      text-align: left;
      top: -8px;
    }
   
  }

  &.MuiAutocomplete-root{
    width: 450px !important;
    margin-top: .5rem;
    @include forPhoneOnly {
      width: 295px !important;
    }
    .MuiFormControl-root{
       .MuiInputBase-input{
        // padding : 0px !important;
        padding-left: 6px !important;
       }
    }
  }
  
}

.friend_referral_box{
  padding: 5px 35px;
  background-color: #F0F7FF;
  border-radius: 10px;
  @include forPhoneOnly{
    padding: 0;
  }
  .subtext-cards{
    &.MuiTypography-root{
      color: #407CBD;
      @include forPhoneOnly{
        text-align: left;
    }
    }
  }
  .card_sub_title_container{
    display:flex;
    justify-content:space-between;
    align-items:center;
  }
}

.MuiCalendarOrClockPicker-root{
  direction: ltr;
}


.popupButtonWrap{
  cursor: pointer;
  .MuiTypography-root{
    font-weight: 500;
  }
  .arrow-icon{
    margin-right: 0;
    margin-left: 10px;
  }
}
.checkListWrap{
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 25px;
  padding-bottom: 25px;
  &:last-child{
    border-bottom: none;
  }
}
.custom-checkbox-Bold{
  &.custom-checkbox{
    width: 100%;
    .MuiButtonBase-root.MuiCheckbox-root + .MuiFormControlLabel-label{
      font-weight: 600;
      text-align: left;
      line-height: 1.2;
    }
  }
}
.checkListWrap{
  .infoNext-Box{
    .MuiTypography-root{
      text-align: left;
    }
  }
}
.custom-form-controler{
  &.custom-form-controler-friendReferral{
    .MuiInputLabel-root{
      &.MuiInputLabel-formControl{
        transform: translate(14px, 14px) scale(1);
        &.MuiInputLabel-shrink{
          transform: translate(14px, -4px) scale(0.75);
        }
      }
    }
    &.custom_form_controler_arabic{
      &.custom-form-controler-friendReferral{
        .MuiInputLabel-root{
          &.MuiInputLabel-formControl{
            transform: translate(-7px, 14px) scale(1);
            &.MuiInputLabel-shrink{
              transform: translate(-7px, -4px) scale(0.75);
            }
          }
        }
    }
  }
}
}
.customArabicDatepicker{
  .MuiPickersCalendarHeader-labelContainer{
    order: 1;
  }
}

.aboutYouContainer{
  .custom-form-controler {
    &.MuiFormControl-root {
      .MuiFormHelperText-root{
        color: rgba($black, 0.6);
        background: none;
        &.Mui-error{
          background-color: $lightRed;
          color: $red;
        }
      }
    }
    }
  }
  .sameInput-custom-form-controler{
    &.custom-form-controler{
      &.MuiFormControl-root{
        .MuiFormLabel-root{
          color: $red;
        }
        .MuiOutlinedInput-notchedOutline{
          border-color: $red;
        }
        .MuiFormHelperText-root{
          background-color: $lightRed;
          color: $red;
        }
    }
  }
}
  
.doneIcon{
  font-size: 18px !important;;
  color: $success !important;; 
  fill: $success !important;
}
.done-icon-padding{
  padding:0px;
}
.cutome-date-picker{
  margin: 10px 0px !important;
}
.overide-width{
  &.MuiFormControl-root{
    width: -webkit-fill-available;
    height: 44px;
    background: #ffffff;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    box-sizing: border-box;
  }
}
.bank-flag {
  position: absolute;
  transform: translateY(25%);
  margin-left: 12px;
  margin-right: 14rem;
  z-index: 1;
}
.banks-auto-complete{
  &.MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall{
  padding-left: 38px
  }
}
.hide-ui{
  display: none;
}
.custom-Autocomplete-wraaper {
  .MuiFormControl-root {
    .MuiInputBase-root {
      padding-top: 9px !important;
      padding-bottom: 9px !important;
    }
    .MuiInputBase-input {
      font-size: 14px;
      line-height: 1.8;
      padding-left: 50px !important;
      margin-right: 24px;
    }
    .MuiFormLabel-root {
      line-height: 1.8;
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
    }
    .MuiFormHelperText-root {
      background: $lightRed;
      border-radius: 4px;
      margin: 5px 0;
      padding: 7px;
      font-weight: 500;
      font-size: 12px;
    }
  }
  &.custom-Autocomplete-wraaper_arabic{
    .MuiInputBase-input {
      padding-right: 30px !important;
    }
  }
  &.auto-display-error{
    .displayError{
      margin: 0;
      padding: 4px 8px;
    }
  }
 
}

.valueAutoComplete1 {
  &.MuiAutocomplete-root {
  .MuiFormControl-root {
    .MuiInputBase-input {
      padding-left: 50px !important;
      line-height: 1.8;
    }
  }
  }
}
#mediNumber{
  box-sizing: border-box;
  // width: -webkit-fill-available;
  width: 337px;
  left: 2px;
}
#mediNumber .error-password .id-card-input-error{
  border: 1px solid #F44336;
 }
#expiryYear, #expiryMonth, #expiryDay{
  box-sizing: border-box;
  // width: 110px;
  width: auto;
}
#datePicker{
  border: 1px solid #d3d3d3;
}
.error-password .MuiFormLabel-root.MuiInputLabel-root {
  color: #F44336;
}
.id-card-input-error  {
  border: 1px solid #F44336!important;
 }
 #mediNumber-error{
  box-sizing: border-box;
  // width: -webkit-fill-available;
  border: 1px solid #F44336;
  width: 337px;
  left: 2px;
}
.input-success{
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: green;
  }
  .MuiInputLabel-root.Mui-focused{
    color: rgba(0, 0, 0, 0.6);
  }
}
.input-error{
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: red;
  }
  .MuiInputLabel-root.Mui-focused{
    color: red;
  }
}
.input-normal{
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: rgba(0,0,0,0.23);
  }
  .MuiInputLabel-root.Mui-focused{
    color: rgba(0,0,0,0.23)
  }
}
@media (min-width: 600px) {
  #mediNumber {
    box-sizing: border-box;
    width: -webkit-fill-available;
    left: 0px;
  }
  #expiryYear, #expiryMonth, #expiryDay {
    box-sizing: border-box;
    width: -webkit-fill-available;
  }
  #datePicker {
    border: 1px solid #d3d3d3;
    width: -webkit-fill-available;
}
}
@media screen and  (max-width:'590px'){
  #mediNumber {
    box-sizing: border-box;
    width: 337px;
    left: 15px;
  }
  #datePicker {
    border: none;
}
}