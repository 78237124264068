.MuiButtonBase-root.MuiCheckbox-root{
    color: $checkboxColor;
    &:hover{
        color: $primary;
    }
    &.Mui-checked{
        color: $primary;
    }
    + .MuiFormControlLabel-label{
        color: rgba($black, 0.68);
        font-size: 11px;
    }
}