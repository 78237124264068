.MuiButton-root {
    &.MuiButton-contained {
      background: $primary;
      text-transform: inherit;
      border-radius: 6px;
      box-shadow: none;
      padding: 8px 15px;
      font-size: 14px;
      transition: all ease 0.3s;
      &:hover{
        background: $buttonHoverBg;
        box-shadow: none;
      }
      &:focus{
        background: $buttonClickBg;
        box-shadow: none;
      }
      &.Mui-disabled{
        background-color: transparent;
        border: 2px solid #F0F0F0;
      } 
    &.round-corner-button{
      border-radius: 30px;
    }
    }
  }

  .MuiButton-root {
    &.MuiButton-outline {
      border: 1px solid $primary;
      color: $primary;
      text-transform: inherit;
      border-radius: 6px;
      box-shadow: none;
      padding: 8px 15px;
      font-size: 14px;
      transition: all ease 0.3s;
      &:hover{
        background: $buttonOutLineHover;
        box-shadow: none;
      }
      &:focus{
        background: $buttonOutLineClick;
        box-shadow: none;
      }
      &.Mui-disabled{
        background-color: $disableBg;
        border: 1px solid $disableBorder;
      }
    }
  }


.MuiButton-root {
    &.MuiButton-text {
      color: $primary;
      text-transform: none;
      background: transparent !important;
      border-radius: 30px;
    }
}