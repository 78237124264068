@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/ColorVariable.scss";

.error_Container {
    background: #FDF0F2;
    // border-top: 1px solid #c4c4c4;
    .heading-1 {
      font-weight: 700 !important;
      font-size: 18px !important;
      line-height: 25px !important;
      letter-spacing: 0.03em;
      margin-left: 24px;
      @include forPhoneOnly {
        margin-left: 8px;
      }
    }

    .heading-error {
      font-family: Noto Sans;
      font-size: 18px;
      font-weight: 700;
      line-height: 24.52px;
      letter-spacing: 0.03em;
      text-align: center;
      margin-left: 10px;
    }
  }
    .login_container {
      .error_Container {
        background: #FDF0F2;
        border-top: 1px solid #c4c4c4;
        margin-top: 67px;
        &.locked_error {
          margin-top: 135px;
        }
        .heading-1 {
          font-weight: 700 !important;
          font-size: 18px !important;
          line-height: 25px !important;
          letter-spacing: 0.03em;
          margin-left: 24px;
          @include forPhoneOnly {
            margin-left: 8px;
          }
        }
    }
  
    .subtext-cards {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.03em;
      margin-top: 8px;
      //max-width: 597px;
      text-align: center;
      @include forPhoneOnly {
        margin-top: 0;
        margin-left: 32px;
        text-align: initial;
      }
    }
  }
  
  .error-alert {
    display: flex;
    justify-content: center;
    align-items: center;
    @include forPhoneOnly {
      justify-content: center;
      align-items: center;
    }
    &.flex-column{	
      flex-direction: column;	
      img{	
        width: 60px;	
      }	
      @include forPhoneOnly {	
        align-items: center;	
      }	
    }
  }
  
  .error-para {
    padding: 24px 0;
    @include forPhoneOnly {
      padding: 12px 24px;
    }
  }