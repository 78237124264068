.verify-wrapper {
    .stepper-box-wrapper  {
        margin-top: 0 !important;
        .stepper-dashboard {
            margin-top: 0 !important;
        }
    }
    .get-verified-container {
        @extend %flex-column;
        width: 38.125rem !important;
        padding: 4rem 6rem !important;
        align-items: center;
        border-radius: 0.75rem;
        border: 1px solid rgba(0, 0, 0, 0.18);
        background: $white;
        .get-verified-card {
            @extend %flex-column;
            gap: 2rem;
            align-self: stretch;
            .get-verified-header-wrapper {
                @extend %flex-column;
                gap: 1.5rem;
                .get-verified-header {
                    color: $black;
                    text-align: center;
                    font-size: 1.5rem;
                    font-weight: 700;
                    line-height: 2.813rem;
                }
            }
            .get-verified-details-wrapper {
                @extend %flex-column;
                align-self: stretch;
                gap: 0.5rem;
                .get-verified-details {
                    @extend %flex-column;
                    justify-content: center;
                    width: 21.875rem;
                    padding: 1rem 1.5rem;
                    gap: 1rem;
                    border-radius: 0.75rem;
                    border: 1px solid $buttonBorderColor;
                    background: $white;
                    .text-small {
                        color: $black;
                        text-align: center;
                        font-size: 0.875rem;
                        font-weight: 400;
                        line-height: 1.25rem;
                        letter-spacing: 0.42px;
                        .copy-icon-btn {
                            .copy-icon {
                                width: 0.875rem;
                                height: 0.875rem;
                                color: rgba(0, 113, 235, 1);
                            }
                        }
                    }
                    .copy-download-btns-wrapper {
                        display: flex;
                        align-items: flex-start;
                        gap: 8px;
                        .small-white-btn {
                            @extend %btn-style;
                            @extend %small-btn-style;
                            padding: 0.25rem 1rem;      
                            color: rgba(0, 0, 0, 0.68);
                        }
                        &.mobile-btn-style {
                            display: block;
                            width: 100%;
                            .small-white-btn {
                                width: 100%;
                                &:last-child {
                                    margin-top: 0.5rem;
                                }
                            }
                        }
                    }
                }
            }
            .get-verified-footer {
                @extend %flex-column;
                gap: 0.75rem;
                .info {
                    display: flex;
                    align-items: center;
                    padding: 0.5rem;
                    gap: 0.5rem;
                    border-radius: 0.375rem;
                    background: $buttonOutLineHover;
                    color: #212121;
                    font-size: 0.75rem;
                    font-weight: 400;
                    line-height: 0.875rem;
                }
                .get-verified-btn {
                    @extend %btn-style;
                    flex: 1 0 0;
                    width: 11.25rem;
                    padding: 0.75rem 1rem;
                    border: 2px solid $primary;
                    background: $primary !important;
                    color: $white;
                    font-weight: 700;
                    letter-spacing: 0.42px;
                }
                .help-text {
                    color: $black;
                    text-align: center;
                    font-size: 0.75rem;
                    font-weight: 400;
                    line-height: 0.875rem;
                    padding-top: 2px;
                    .chat-with-us {
                        cursor: pointer;
                        color: $primary;
                    }
                }
            }
        }
    }
}

.info-board-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // width: 100vw;
    height: 100%;
    padding: 3.75rem 0rem;
    gap: 2rem;
    background: #F3F5F6;
    box-sizing: border-box;
    .info-board {
        display: flex;
        width: 45.313rem;
        padding: 5.25rem 4rem;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        border-radius: 0.75rem;
        background: #FFF;
        .info-board-content {
            color: #000;
            text-align: center;
            font-size: 1.75rem;
            font-weight: 400;
            line-height: 2.25rem; 
            letter-spacing: 0.84px;
        }
        .info-card {
            display: inline-flex;
            padding: 0.5rem;
            align-items: center;
            border-radius: 0.375rem;
            background: #F0F7FF;
            color: #212121;
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 0.875rem;
            margin-top: 0.75rem;
            & .facta-requirement {
                color: #212121;
                font-weight: 700;
                line-height: 14px;
                text-decoration-line: underline !important;
                text-decoration-style: solid !important;
                text-decoration-skip-ink: none;
                text-decoration-thickness: auto !important;
                text-underline-offset: auto;
                text-underline-position: from-font;
            }
        }
    }
}

.bw-icon{
    position: relative;
    margin-right: 6%;
    margin-top: 2%;
    cursor: pointer;
}

.message-block {
    width: 53rem !important;
}

.manual-upload-container {
    align-items: start !important;
    &.manual-review-container {
        @extend %flex-column;
    }
    #input-manual-file-upload {
        display: none;
    }
    .drag-drop-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 26rem;
        padding: 1rem;
        gap: 1.75rem;
        border-radius: 0.5rem;
        border: 1px dashed #0071EB;
        background: #F0F7FF;
        .img-placeholder{
            width: 6.688rem;
        }
        .file-drop-container {
            max-width: 13rem;
            .manual-upload-btn {
                width: 13rem !important;
            }
        }
    }
    .uploaded-img-container {
        position: relative;
        width: 14.125rem;   
        max-width: 14.125rem;
        border: 1px dashed #0071eb;
        margin-bottom: 1rem;
        border-radius: 0.5rem;
        .delete-icon {
            border: 1px solid #D3D3D3;
            padding: 0px;
            background: white !important;
            border-radius: 0.5rem;
            position: absolute;
            top:3%;
            left: 80%;
            width: 2.5rem !important; 
            height: 2.5rem;
            color: #0071EB;
            z-index: 2;
        }
        .uploaded-img {
            width: 14.125rem;  
            max-width: 14.125rem;
            border-radius: 0.5rem;
            .react-pdf__Page {
                position: relative;
                z-index: 0;
                .react-pdf__Page__canvas {
                    width: 14.125rem;
                    max-width: 14.125rem;
                    max-height: 10rem;
                    border-radius: 0.5rem;
                }
            }
        }
    }
}

.MuiButton-root {
    &.MuiButton-text {
        &.manual-upload-btn{
            @extend %btn-style;
            width: 21.438rem;
            padding: 0.75rem 1rem;
            border: 2px solid #0071EB;
            background: #0071EB !important;
            color: #FFF;
            text-align: center;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 0.42px;
            cursor: pointer;
            &.Mui-disabled{
                opacity: 0.6;
            }
        }
        &.white-small-btn {
            @extend %btn-style;
            @extend %small-btn-style;
            width: 12.5rem;
            padding: 0.75rem 1rem; 
            color: $primary;
            &.Mui-disabled{
                opacity: 0.6;
            }
            &:hover {
                text-decoration: none;
                background-color: rgba(25, 118, 210, 0.04) !important;
                border: 1px solid rgb(25, 118, 210);
            }
        }
        &.primary-small-btn {
            @extend %btn-style;
            width: 12.5rem;
            padding: 0.75rem 1rem; 
            background: #0071EB !important;
            color: #FFF;
            &.Mui-disabled{
                opacity: 0.6;
            }
            &:hover {
                background: #66aaf3 !important;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .verify-wrapper {
        .get-verified-container {
            width: 20.438rem !important;
            padding: 4rem 1.5rem !important;
            border: none;
            .get-verified-details {
                width: 18.438rem !important;
                padding: 1rem 1rem !important;
            }
        }
    }
    .message-block {
        width: 100% !important;
    }
    .info-board-container {
        padding: 0;
        background-color: #FFF;
        .info-board {
            width: auto;
            padding: 5.25rem 1.5rem;
            .info-board-content {
                font-size: 1.25rem;
                line-height: 1.75rem;
                letter-spacing: 0.6px;
            }
            .manual-upload-btn {
                width: 18.438rem
            }
        }
    }
    .manual-upload-container {
        .drag-drop-container {
            width: 19.2rem;
            .img-placeholder-container {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 374px) {
    .verify-wrapper {
        .get-verified-container {
            width: 20rem !important;
            .get-verified-details {
                width: 15rem !important;
            }
        }
        .message-block {
            width: 100% !important;
        }
        .info-board-container {
            .info-board {
                padding: 5.25rem 0.8rem;
                .info-card{
                    font-size: 0.7rem;
                }
            }
        }
    }
    .manual-upload-container {
        .drag-drop-container {
            width: 16rem;
        }
    }
}

@media only screen and (min-width: 767px) and (max-width: 860px) {
    .info-board-container {
        .info-board {
            width: 39.5rem;
        }
    }
}

%flex-column {
    display: flex !important;
    flex-direction: column;
    align-items: center !important;
}

%btn-style {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    line-height: 1.375rem;
}

%small-btn-style {
    gap: 0.25rem;
    border: 1px solid $buttonBorderColor;
    background: rgba(243, 243, 243, 0.10);
}