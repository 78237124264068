@import "../../../assets/scss/ColorVariable.scss";

@mixin responsiveHelperWidth {
    width: 447px !important;
  @media (max-width: 600px) {
    width: 343px !important;
  }
}

.customer-header2 .custom-header{
  top: 59px !important;
  @media (max-width: 900px) {
    top: 48px !important;
  }
  // @media (max-height: 599px) {
  //   top: 44px !important;
  // }
}
.sub-header {
  .MuiTypography-root {      
      letter-spacing: 0.4px;
      font-size: 12px !important;
    }
  }

.launch-wrapper {
  .MuiTypography-h4 {
    font-size: 20px;
    color: rgba($black, 0.68);
  }
  .MuiTypography-h5 {
    font-size: 15px;
    color: $primary;
  }
}
.textField_label {
  .MuiFormLabel-root {
    font-size: 14px;
  }
}

.email-select{
  &.MuiAutocomplete-root {
    .MuiFormControl-root {
      .MuiInputBase-root {
        // padding-bottom: 13px !important;
      }
      .MuiInputBase-input { 
        padding-left: 4px !important;
      }
    }
  }
}

.custom-Autocomplete-wraaper {
  .MuiFormControl-root {
    .MuiInputBase-root {
      padding-top: 9px !important;
      padding-bottom: 9px !important;
    }
    .MuiInputBase-input {
      font-size: 14px;
      line-height: 1.8;
      padding-left: 50px !important;
      margin-right: 24px;
    }
    .MuiFormLabel-root {
      line-height: 1.8;
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
    }
    .MuiFormHelperText-root {
      background: $lightRed;
      border-radius: 4px;
      margin: 12px 0px -2px 0px;
      padding: 0px 2px 6px 4px;
      font-weight: 500;
      font-size: 12px;
      color: $red;
    }
  }
  &.custom-Autocomplete-wraaper_arabic{
    .MuiInputBase-input {
      padding-right: 30px !important;
    }
  }
  &.auto-display-error{
    .displayError{
      margin: 0;
      padding: 4px 8px;
    }
  }
 
}

.valueAutoComplete {
  &.MuiAutocomplete-root {
  .MuiFormControl-root {
    
    .MuiInputBase-input {
      padding-left: 50px !important;
      line-height: 1.8;
      color: rgb(252, 113, 113);
    }
  }
  }
  &.valueAutoComplete_Arabic{
    .MuiInputBase-input {
       padding-right: 50px !important;
    }
   
  }
}
.valueAutoComplete1 {
  &.MuiAutocomplete-root {
  .MuiFormControl-root {
    .MuiInputBase-input {
      padding-left: 50px !important;
      line-height: 1.8;
    }
  }
  }
}
.currentlocationSty {
  display: flex;
  flex-direction: column;
  
  .countryName {
    font-size: 12px;
    color: #000000;
    margin-top: -3px;
  }
  
}
.currenLocText {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.68);
}
.p-18 {
  padding-left: 18px !important;
}
.location_divider {
  border: 2px solid #F0F0F0 !important;
  margin-top: 10px;
  margin-bottom: 10px;
}
.MuiAutocomplete-popper {
  .MuiAutocomplete-listbox {
    padding: 10px !important;
  }
}

.error-password{
  .MuiFormLabel-root.MuiInputLabel-root{
    color: $red;
  }
}
.Footer_text {
  .sub-text {
    font-size: 12px;
    margin-top: 4px;
    letter-spacing: 0.4px;
    line-height: 19px;
  }
  .sub-text_Arabic{
    text-align: right !important;
    @extend .sub-text;
  }
}

.passowrd-validation-arabic{
  margin-right: -26px !important;
}
