@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/ColorVariable.scss";

.box-margin{
  margin-top: 97px;
}

.login-bottom-text {
  &.MuiTypography-root {
    font-size: 12px;
    span {
      cursor: pointer;
      color: $primary;
      margin-left: 3px;
      font-weight: 700;
    }
  }
}

.forgot-username{
  .custom-form-controler {
    &.MuiFormControl-root {
      .MuiFormHelperText-root{
        color: rgba($black, 0.6);
        background: none;
        &.Mui-error{
          background-color: $lightRed;
          color: $red;
        }
      }
    }
    }
}

.container-home {
  .MuiBox-root {
    .heading-1 {
      @include forPhoneOnly {
        font-size: 36px;
      }
    }
    .heading-1_Arabic{
      font-size: 36px;
      font-weight: 300;
      color: #000000;
      @include forPhoneOnly {
        font-size: 28px;
      }
    }
    .subtext-cards {
      @include forPhoneOnly {
        font-size: 14px;
        margin-left: 0;
        text-align: center;
      }
    }
  }
}

.support-text {
  &.MuiTypography-root {
    font-size: 14px;
    font-weight: 400;
    color: $supportTextColor;
    span {
      cursor: pointer;
      color: $primary;
      margin-left: 3px;
    }
  }
}

.forgot-button {
  .MuiButton-root {
    &.MuiButton-outline {
      border: 1px solid $buttonBorderColor;
    }
  }
  .MuiButton-root {
    &.MuiButton-contained {
      &.Mui-disabled {
        color: #c4c4c4;
        border: 1px solid #f0f0f0;
        background: $buttonBackgroundColor;
      }
      color: #ffffff;
      background: $primary;
      box-shadow: none;
      border: 1px solid #0071eb;
    }
  }
}
.forgot-button_Arabic{
  @extend .forgot-button;
  .MuiButton-root {
    &.MuiButton-outline {
      font-weight: 700;
    }
  }
  .MuiButton-root {
    &.MuiButton-contained {
      font-weight: 700;
    }
  }
}

.update_password {
  .MuiButton-root {
    &.MuiButton-contained {
      align-items: center;
      min-width: 140px;
      @include forPhoneOnly {
        min-width: 344px;
      }
    }
  }
}


/**********************************************************/

.unsuccessful_bar {

  background: #FDF0F2;
  margin-bottom: 30px !important;
  margin-top: -29px !important;
  padding: 8px 30px;
  height: 101px;


}

.unsuccessful_bar-title {

height: 25px;
font-family: 'Noto Sans';
font-style: normal;
font-weight: 700 !important;
font-size: 18px !important;
line-height: 25px;
text-align: center !important;
letter-spacing: 0.03em;
color: #222222;
flex: none;
order: 1;
flex-grow: 0;
margin-top: -30px !important;
margin-right: -70px !important;
}

.unsuccessful_bar-subtext {
    width: 1360px;
    height: 20px;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.03em ; 
    color: rgba(0, 0, 0, 0.68);
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-left: -40px !important;
    margin-top: 8px !important;
}

.unsuccessful-warn {  
  width: 20px;
  height: 40px;
  position: relative;
  right: 145px;
  top: 8px;
}

.loginUserName {
  .MuiInputBase-root.Mui-error{
      .MuiInputBase-input{
        color:#F44336;
      }
    }
}

@media screen and (max-width: 375px) {

  .unsuccessful_bar {
    height: 86px;
  }

  .unsuccessful_bar-title {
    width: 295px;
    height: 22px;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 22px;
    letter-spacing: 0.03em;
    color: #222222;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-top: -30px !important;
    margin-left: 15px !important;
  }

  .unsuccessful_bar-subtext {
    text-align: justify !important;
    width: 295px !important;
    height: 40px !important;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: rgba(0, 0, 0, 0.68) !important;
     margin-top: 4px !important;
     margin-left: 32px !important;
  }
}


.apiFailNotify {
  position: absolute !important;
  left: 25% !important;
  top: 270px;
  border: 1px solid #ff0000;
  width: 450px;
  height: 78px;
  border-left-width: 17px;
  border-left-style: solid;
  border-left-color: #ff0000;
  border-radius: 10px !important;
  padding: 0px 3px 3px 1px;
  background: #FDF0F2;
}


.apiFailNotify_text {
  position: relative;
  top: -40px;
  padding-left: 30px;
  margin-bottom: -45px !important;
  margin-left: 17px;
  color: rgba(0, 0, 0, 0.68) !important;
}

@media screen and (max-width: 375px) {
  .apiFailNotify {
    width:85%;
    height: 17%;
    position: absolute;
    top: 40%;
    left: 5% !important;
    transform: translateY(-50%);
  }
  
  .apiFailNotify_text {
    font-size: 13px !important;
  }

}
/*************ARABIC FLOW*****************************************************************/

.rememberMe_Arabic {
  margin-right: -27px;
}

.ForgotButton_Arabic {
  .MuiButton-root {
    &.MuiButton-outline {
      font-weight: 700;
    }
  }
}


.forgot-usernam-auto {
  .MuiOutlinedInput-root {
  padding-right: 5px !important;
  }
}

.forgotChekbox{
  .MuiFormControlLabel-root{
    align-items: flex-start;
    margin-top: 15px;
    .MuiButtonBase-root.MuiCheckbox-root{
      padding-top: 3px;
    }
  }
}

.forgotChekbox .MuiFormControlLabel-label {
  padding-top: 5px !important;
}