$error-background: #fdf0f2;
$error-border-color: #ff0000;

.error-notification {
  position: fixed !important;
  left: 64% !important;
  top: 16%;
  border: 1px solid $error-border-color;
  width: 450px;
  //height: 60px;
  border-left-width: 17px;
  border-left-style: solid;
  border-left-color: $error-border-color;
  border-radius: 10px !important;
  padding: 0px 3px 3px 1px;
  background: $error-background;
  z-index: 9999 !important;
}

.warning-notification {

  display: flex;
  align-items: center;

  position: fixed !important;
  // left: 65% !important;
  right: 30px;
  top: 22%;
  border: 1px solid #dda034;
  width: 440px;
  // height: 72px;
  border-left-width: 17px;
  border-left-style: solid;
  border-left-color: #dda034;
  border-radius: 10px !important;
  padding: 0px 3px 3px 1px;
  background: #fff8ea;
  z-index: 1000 !important;
  &.arabicWarning-notification{
    justify-content: flex-end;
    border-left-width: 1px;
    border-right-width: 17px;
    .error-text-warning{
      text-align: right;
    }
    .warning-icon{
      order: 1;
    }
    .close-icon-warning {
      position: absolute !important;
      bottom: 50% !important;
      transform: translateY(50%);
      left: 10px;
      cursor: pointer;
  }
  }
}

.warning-icon {
  padding: 21px 16px;
}

.error-text {
  position: relative;
  top: -30px;
  padding-left: 40px;
  margin-bottom: -45px !important;
  margin-left: 20px;
  color: rgba(0, 0, 0, 0.68) !important;
}

.errText-Arabic {
  position: relative;
  top: -30px;
  padding-right: 40px;
  margin-bottom: -45px !important;
  margin-right: 20px;
  color: rgba(0, 0, 0, 0.68) !important;

}

.error-text-warning {
  position: relative;
  // top: -72px;
  // padding-left: 40px;
  // margin-bottom: -45px !important;
  margin-left: 20px;
  color: rgba(0, 0, 0, 0.68) !important;
}

.close-icon {
  position: relative !important;
  left: 92% !important;
  bottom: 28% !important;
}

.closeIcon-Arabic {
  position: relative !important;
  right: 92% !important;
  bottom: 28% !important;
}


.close-icon-warning{
  position: relative !important;
  // left: 93% !important;
  bottom: 82% !important;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .error-notification {
    width: 80%;
    min-height: 7%;
    height: auto;
    position: absolute;
    left: 7% !important;
    top: 15%;
    transform: translateY(-50%);
  }

  .warning-notification {
    width: 80%;
    min-height: 7%;
    // height: auto;
    position: absolute;
    left: 7% !important;
    top: 90%;
    transform: translateY(-50%);
  }

  .error-text {
    font-size: 13px !important;
    padding-top: 12px;
  }

  .error-text-warning {
    font-size: 12px !important;
    font-weight: 400;
    max-width: 195px;
    line-height: 20px;
    letter-spacing: 0.5px;
  }

  .cross-icon {
    position: relative;
    top: -15%;
    padding-left: 20px !important;
    margin-bottom: -29px !important;
  }

  .close-icon {
    position: relative !important;
    left: 92% !important;
    bottom: 28% !important;
  }

  .close-icon-warning {
    position: relative !important;
    // left: 88% !important;
    bottom: 94% !important;
    cursor: pointer;
  }
}
