.heading-1{
    font-weight: 500;
    &.MuiTypography-root{
        font-size: 36px;
        font-weight: 300;
        padding-top: 10px;
        @include forPhoneOnly{
            font-size: 24px;
        }
    }
    &.midleTextBlack{
        color: rgba($black, 1) !important;
        &.MuiTypography-root{
            span{
                font-weight: 600;
                color: rgba($black, 1) !important;
            }
        }
    }
    &.noSubHeader-midleTextBlack{
        line-height: 45px;
        margin-top: 16px;
    }
    &.medium-weight{
        font-weight: 500;
    }
}

.heading-2{
    &.MuiTypography-root{
        font-size: 32px;
        font-weight: 400;
        @include forPhoneOnly{
            font-size: 24px;
        }
    }
}

.sub-header{
    &.MuiTypography-root{
        font-size: 26px;
        font-weight: 300;
        @include forPhoneOnly{
            font-size: 28px;
        }
    }
}
.sub-header-TOB{
    &.MuiTypography-root{
        font-size: 28px;
        font-weight: 300;
        @include forPhoneOnly{
            font-size: 24px;
        }
    }
}
.subtext-cards{
    &.MuiTypography-root{
        font-size: 14px;
        font-weight: 400;
        color: rgba($black, 0.68);
        @include forPhoneOnly{
            font-size: 12px;
        }
    }
}
.text-button{
    &.MuiTypography-root{
        font-size: 14px;
        font-weight: 500;
    }
}
.error-PopUp-disclaimer-text{
    &.MuiTypography-root{
        font-size: 12px;
        font-weight: 500;
    }
}
.paragraph-helper-text{
    &.MuiTypography-root{
        font-size: 11px;
        font-weight: 500;
    }
}