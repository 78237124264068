.country-flag {
  position: absolute;
  transform: translateY(30%);
  margin-left: 12px;
}
.country-flag-arabic {
  position: absolute;
  transform: translateY(30%);
  margin-right: 12px;
}

.success-helper {
    .MuiInputBase-input {
      color: currentColor !important;
    }

    .MuiAutocomplete-root{
    .MuiFormControl-root {
      .MuiInputBase-input {
        color: rgb(56, 182, 52) !important;
      }
    }}

    .MuiFormHelperText-root {
      background: #E5FAF4 !important;;
      color: #03CF92 !important;
        a{
          text-decoration: underline !important;
          color:#0071EB;
        }
    }

    .MuiInputBase-root.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
      border-color: rgba(0, 0, 0, 0.6) !important;
    }
    .MuiFormLabel-root.MuiInputLabel-root.Mui-error{
      color: rgba(0, 0, 0, 0.6) !important;
    }
}

  
  
