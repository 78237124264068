@import '../../assets/scss/mixin.scss';
@import '../../assets/scss/ColorVariable.scss';

.currency-form-controlar {
    @include forPhoneOnly{
        width: 100%;
    }
    .MuiRadio-root {
        display: none;
    }

    .MuiFormControlLabel-label {
        background: #F8F8F8;
        border-radius: 30px;
        border: 2px solid transparent;
        transition: all ease 0.4s;
        min-width: 160px;
        &:hover{
            background-color: $primary;
            .MuiTypography-root{
                color: $white;
            }
            
        }
        @include forPhoneOnly{
            width: 100%;
        }
    }

    .MuiCardContent-root {
        display: flex;
        align-items: center;
        padding: 10px 20px !important;
        img{
            width: 30px;
            @include forPhoneOnly{
                width: 25px;
            }
        }
        @include forPhoneOnly{
            padding: 6px 10px !important;
        }

        .MuiTypography-root {
            color: rgba($black, 0.68);
            text-align: left;
            margin-left: 10px;
            font-weight: 500;
            font-size: 14px;
            @include forPhoneOnly{
                font-size: 12px;
            }
            small {
                display: block;
                font-size: 14px;
                font-weight: 500;
            }
        }
    }

    .MuiFormControlLabel-root{
        margin: 0 6px;
    }
    .MuiCard-root {
        background: transparent;
        box-shadow: none;
    }

    .Mui-checked {
        +.MuiFormControlLabel-label {
            background: $primary;
            border: 2px solid $primary;
            .MuiTypography-root{
                color: $white;
            }
        }
    }
}