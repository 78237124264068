.custom-form-controler {
  &.MuiFormControl-root {
    .MuiFormLabel-root {
      background: $white;
      padding: 0 5px;
      font-size: 14px;
      line-height: 1;
      font-weight: 400;
      height:20px;
      &.Mui-focused {
        transform: translate(14px, -4px) scale(0.75);
      }

      &.MuiInputLabel-shrink {
        transform: translate(14px, -4px) scale(0.75);
      }
    }

    .MuiSelect-select {
      display: flex;
      align-items: center;
      padding: 10px 30px 10px 20px !important;
    }

    .MuiInputBase-input {
      padding: 13px !important;
      max-height: 40px;
      font-size: 14px;
    }
    .MuiFormLabel-asterisk {
      display: none;
    }
    .MuiFormHelperText-root {
      margin: 5px 0;
      padding: 7px;
      background-color: $lightRed;
      border-radius: 4px;
      color: $red;
      font-weight: 500;
    }
    .Mui-error.MuiFormLabel-root{
      color: $red;
    }
    .MuiInputBase-root.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
      border-color: $red;
    }
  }
  &.custom_form_controler_arabic{
    &.MuiFormControl-root {
    .MuiFormLabel-root {
      &.Mui-focused {
        transform: translate(-10px, -4px) scale(0.75);
      }

      &.MuiInputLabel-shrink {
        transform: translate(-10px, -4px) scale(0.75);
      }
    }
  }
  }
}
.custom-form-controler-error {
  &.MuiFormControl-root {
    .MuiFormLabel-root {
      background: $white;
      padding: 0 5px;
      font-size: 14px;
      line-height: 1;
      font-weight: 400;

      &.Mui-focused {
        transform: translate(14px, -4px) scale(0.75);
      }

      &.MuiInputLabel-shrink {
        transform: translate(14px, -4px) scale(0.75);
        color: $red;
      }
    }

    .MuiSelect-select {
      display: flex;
      align-items: center;
      padding: 10px 30px 10px 20px !important;
    }

    .MuiInputBase-input {
      padding: 11px 20px !important;
      font-size: 14px;
      color: $red;
      border: 1px solid $red;
      border-radius: 4px;
    }
    .MuiFormLabel-asterisk {
      display: none;
    }
    .MuiFormHelperText-root {
      margin: 5px 0;
      padding: 7px;
      background-color: $lightRed;
      border-radius: 4px;
      color: $red;
      font-weight: 500;
    }
  }
  &.custom_form_controler_arabic{
    &.MuiFormControl-root {
    .MuiFormLabel-root {
      &.Mui-focused {
        transform: translate(-10px, -4px) scale(0.75);
      }

      &.MuiInputLabel-shrink {
        transform: translate(-10px, -4px) scale(0.75);
      }
    }
  }
  }
}

.MuiAutocomplete-root {
  .MuiFormControl-root {
    .MuiInputBase-root {
      padding-top: 9px !important;
      padding-bottom: 9px !important;
    }
    .MuiInputBase-input {
      font-size: 14px;
      padding: 2px 20px !important;
      line-height: 1.8;
    }
    .MuiFormLabel-root {
      line-height: 1.8;
    }
  }
  .MuiInputBase-root.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
    border-color: $red;
  }
  .MuiFormLabel-root.MuiInputLabel-root.Mui-error{
    color: $red;
  }
}
