@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/ColorVariable.scss";

.custom-header {
  border-bottom: #f0f0f0 1px solid;
  position: fixed;
  width: 100%;
   top: 0px !important;
  background: #fff;
  z-index: 999;
}
.custom-country-control {
  &.MuiFormControl-root {
    // width: 120px;
    min-width: 154px;
    margin: 12px 0;
    //padding: 10px 24px;
    @include forPhoneOnly {
      min-width: 110px;
    }
  }
}
.refering_broker {
  font-size: 12px;
    font-weight: 400;
    padding: 10px 26px 10px 26px !important;
    border: 1px solid #d3dce6;
    border-radius: 30px;
    color: rgba(0, 0, 0, 0.68);
    background: #F0F0F0;
    right: 24px;
    @include forPhoneOnly {
      border-radius: 8px;
      padding: 8px 24px !important;
    }
}

.logo-FXCM {
  img {
      width: 146px;
      height: 40px;
    @include forPhoneOnly {
      width: 65px;
      height: 30px;
    }
  }
}
.referring-grid{
  @include forPhoneOnly{
    //margin-right: 40px !important;
  }
}

.custom-country-dropdown {
  .MuiOutlinedInput-notchedOutline {
    border-color: #e6e6e6;
    border-radius: 30px;
  }

  .MuiSelect-select {
    padding: 10px 48px 10px 56px !important;
    // padding-left: 40px !important;
    font-size: 12px;
    color: rgba($black, 0.68);
    font-weight: 400;

    @include forPhoneOnly {
      // text-indent: -999999px;
      // padding: 10px 25px !important;
      // padding-left: 40px !important;
      padding: 10px 44px 10px 16px !important;
      @include forPhoneOnly{
        padding: 10px 30px 10px 16px !important;
      }
    }
    &::before {
      content: "";
      width: 20px;
      height: 20px;
      background-size: contain;
      position: absolute;
      left: 18px;
      top: 50%;
      transform: translateY(-50%);
      @include forPhoneOnly {
        display: none;
        // left: 10px;
      }
    }
  }

  &.MuiInputBase-root {
    border-radius: 30px;
    transition: all ease 0.4s;
    &:hover {
      .MuiSvgIcon-root {
        background: $primary;
        border-radius: 50%;
        color: #ffffff;
      }
    }
    .MuiSvgIcon-root {
      border-radius: 50%;
      content: "";
      background-size: contain;
      position: absolute;
      width: 32px;
      height: 32px;
      right: 8px;
      top: 6px;
      transition: all ease 0.4s;
      @include forPhoneOnly{
        width: 20px;
        height: 20px;
        top: 13px;
      }
    }
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid #e6e6e6 !important;
      }
    }

    &.Mui-focused {
      .MuiSvgIcon-root {
        background: $buttonOutLineHover;
        border-radius: 50%;
        color: $primary;
        border: 0.2px solid $buttonBorderColor;
      }
      .MuiSelect-select[aria-expanded="true"] {
        //color: $white;
        // background: $primary;
        // border-radius: 30px;
        &::before {
          content: "";
          // background: url(../../../assets/images/world-icon-white.svg);
          // background-size: contain;
        }
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: #e6e6e6 !important;
      }
    }

    .MuiSelect-select[aria-expanded="false"] {
      // border: 1px solid #e6e6e6 !important;
      border-radius: 30px;
    }
  }
}

.custom-dropdown-list {
  // &.MuiPopover-root {
  //   right: 20px;
  //   top: 10px;
  // }
  .MuiPaper-root {
    border-radius: 16px;
    margin-top: 4px;
    max-width: 338px;
    max-height: 446px;
    padding: 16px 12px 20px;
    @include forPhoneOnly {
      //min-height: 100%;
      border-radius: 0;
      //margin-top: 120px;
      margin-left: 16px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
  .MuiMenu-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    border-radius: 16px;
    .help-text {
      width: 100%;
      text-align: left;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
      margin-bottom: 20px;
      color: rgba($black, 0.68);
    }
    .MuiMenuItem-root {
      width: 50%;
      font-size: 12px;
      border-radius: 30px;
      padding: 8px 0px 8px 12px;
      color: $black;
      border: 1px solid transparent !important;
      padding-right: 10px;
      &:hover {
        background: $dropdownHover;
        border: 1px solid $primary !important;
      }
      @include forPhoneOnly {
        font-size: 12px;
        min-height: 30px;
      }
      &.Mui-selected {
        background: $primary;
        color: $white;
      }
    }
  }
}

.custom-dropdown-list-esmaBanner{
  @extend .custom-dropdown-list;
  // .MuiPaper-root {
  //   @include forPhoneOnly {
  //     margin-top: 163px;
  //   }
  // }
}
.custom-dropdown-controlar {
  .MuiButtonBase-root {
    background: #ffffff;
    border-radius: 30px;
    border: 1px solid #e6e6e6;
    color: rgba($black, 0.68);
    text-transform: none !important;
    position: relative;
    padding: 10px 50px 10px 20px;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 178px;
    font-size: 12px;
    font-weight: 400;
    margin: 12px 0 13px 0;
    &:hover {
      &::after {
        content: "";
        border-top: 6px solid rgba($white, 1);
      }

      &::before {
        content: "";
        background: rgba($primary, 1);
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 28px;
      height: 28px;
      border-radius: 100%;
      right: 10px;
      transition: all ease 0.4s;
      top: 50%;
      transform: translateY(-50%);
    }

    &::after {
      content: "";
      position: absolute;
      // width: 28px;
      // height: 28px;
      right: 18px;
      transition: all ease 0.4s;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid rgba($black, 0.68);
    }
  }

  .MuiButtonBase-root[aria-expanded="true"] {
    background: $primary;
    // color: $white;
    &::after {
      content: "";
      transform: translateY(-50%) rotate(180deg);
      border-top: 6px solid rgba($primary, 0.68);
    }

    &::before {
      content: "";
      background: $buttonOutLineHover;
      border: 0.2px solid $buttonBorderColor;
    }
  }
}
.custom-dropdown-menu {
  .MuiPaper-root {
    border-radius: 16px;
    margin-top: 4px;
    // max-width: 267px;
    // min-height: 164px;
    padding: 24px 24px ;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    @include forPhoneOnly {
      max-width: 251px;
      min-height: 100%;
      border-radius: 0;
      margin-top: 126px;
      margin-left: 18px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .MuiList-root {
      .name-style {
        font-size: 16px;
        font-weight: 600;
        word-wrap: break-word;
      }
      .username-style {
        margin-top: 8px;
        // padding: 8px 0;
        font-size: 12px;
        font-weight: 400;
        word-wrap: break-word;
      }
      .save-and-logout {
        margin-top: 20px;
        // padding: 13px 30.5px;
        min-width: 243px;
        min-height: 48px;
        border-radius: 8px;
        font-weight: 700;
        border: 1px solid $buttonBorderColor;
        .material-symbols-outlined{
          color: $primary;
          padding:0 8px;
        }
      }
      .MuiMenuItem-root {
        border-radius: 20px;
        font-size: 12px;
        &:hover {
          background: $primary;
          color: $white;
        }
      }
    }
  }
}

.custom-dropdown-menu-Arabic{
  @extend .custom-dropdown-menu;
  .MuiPaper-root {
    @include forPhoneOnly {
      margin-left: 0;
      left: 0 !important;
    }
  }
}


.save-and-logoutArabic{
  @extend .save-and-logout;
  display: flex;
  flex-direction: row-reverse;
}
.custom-dropdown-menu-esmabanner{
  @extend .custom-dropdown-menu;
  .MuiPaper-root {
    @include forPhoneOnly {
      margin-top: 166px;
    }
  }
}

.custom-dropdown-menu-Arabic-esmabanner{
  @extend .custom-dropdown-list-esmaBanner;
  .MuiPaper-root {
    @include forPhoneOnly {
      margin-left: 0;
      left: 0 !important;
    }
  }
}

.display-none {
  @include forPhoneOnly {
    display: none;
  }
}

.liveLogo {
  margin-left: 8px;
  margin-right: 8px;
}

.menu-class {
  &.MuiButton-root {
    &.MuiButton-text {
      padding: 12px;
      margin-left: 12px;
      @include forPhoneOnly {
        margin-left: 0;
        margin: 12px 0;
      }
    }
  }
}

.button-text-Mobile {
  padding: 12px 16px;
  border: 1px solid $buttonBorderColor;
  border-radius: 4px;
}

.button-textArabic{
  flex-direction: row-reverse;
}

.button-Mobile {
  &.MuiButton-root {
    &.MuiButton-text {
      padding: 12px 0;
      &.button-liveChat{
        &.Mui-disabled{
          img{
            filter: grayscale(100%);
            opacity: 0.4;
          }
        }
      }
      
    }
  }
}