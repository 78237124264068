.loader-class {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.75s, visibility 0.75s;
}

.loader {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 10px solid #f4f4f4;
  // margin: 10% 40%;
  position: relative;
  z-index: 1;
}

.loader:before {
  content: "";
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border-color: #0071eb transparent transparent transparent;
  border-style: solid solid solid solid;
  border-width: 10px 0 0 0;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid transparent;
  position: absolute;
  z-index: 9;
  top: -10px;
  left: -10px;
  animation: rt 1.5s ease infinite;
}

@keyframes rt {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader > .loader-logo {
  text-align: center;
  display: block;
  margin: 24%;
}
