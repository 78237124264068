@import "../../assets/scss/mixin.scss";

.MuiDrawer-root {
  &.MuiDrawer-modal {
    .MuiPaper-root {
      border-radius: 16px 16px 0 0;
      box-shadow: 0px 48px 100px rgba(0, 0, 0, 0.15);
    }
  }
}
.drawer-class {
  max-width: 400px;
  padding: 24px 24px 48px 24px;
  background: #ffffff;
  .notification-Icon {
    display: flex;
    justify-content: space-between;
    .closePopIcon {
      cursor: pointer;
    }
  }
  .drawer-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 30px;
    letter-spacing: 0.03em;
    padding: 0;
    margin-top: 24px;
  }

  .subtext-cards {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.03em;
    margin-top: 24px;
  }
  .button-group {
    padding: 0;
    margin-top: 24px;
    display: flex;
    justify-content: center;
    .MuiButtonBase-root {
      @include buttonSize(196px);
      @include forPhoneOnly {
        min-width: 159.5px;
      }
      min-height: 48px;
      text-transform: capitalize;
      font-weight: 700;
      top: 0;
    }
    .MuiButton-outlined {
      border: 1px solid #d3d3d3;
      margin-right: 8px;
    }
  }
}
.drawer-button {
  .MuiButton-root.MuiButton-text {
    padding: 0;
  }
}
