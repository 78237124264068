$primary: #0071EB;
$buttonHoverBg: #66AAF3;
$buttonOutLineHover:#F0F7FF;
$buttonOutLineClick:#C7E2FF;
$buttonClickBg: #338DEF;
$white: #fff;
$black: #000;
$lightRed: #FDF0F2;
$red: #F44336;
$disableBg:#EEEEEE;
$disableBorder:#BDBDBD;
$dropdownHover:#E6E6E6;
$checkboxColor:#525252;
$acknowledementBoxColor:#fee9c0;
$formLabelColor: rgba(0, 0, 0, 0.44);
$disclaimerBackgroundColor: #FAFAFA;
$bgDisable: #F0F0F0;
$colorDisable: #C4C4C4;
$supportTextColor: rgba(0, 0, 0, 0.68);
$buttonBorderColor: #D3D3D3;
$buttonBackgroundColor: #f0f0f0;
$topNotipficationBar: #4F566B;
$success:#20C374;
$primarySilver: #B4C5D7;
$textColor: #2B3541;
$background: #0C51A3;
$hoverOver: rgba(230, 247, 253, 0.5); 


