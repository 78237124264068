span {
  color: #0071eb;}
ul{
   margin-left: -20px;
  }

  .displayError{
    color: #F44336;
    background: #FDF0F2;
    margin-left: -13px;
    padding: 8px 12px;
    border-radius: 4px;
    background-size: cover;
    margin-right: -13px
    }

    a {
      cursor: pointer;
    }

    .liveChatMessage{
      font-size: 90%;
      color: #6d6d6d;
    }